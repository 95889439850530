import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, db } from '../../firebase/Firebase';
import axios from 'axios';

export const getMembers = createAsyncThunk('members/getMembers', async (params: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await axios.post(`${process.env.REACT_APP_API}/user/listByAdmin`, {
    ...params,
    token,
  }).then((result: any) => {
    return result.data;
  });

  if (data.code === 100) {
    return data;
  } else {
    return null;
  }
});

export const getMember = createAsyncThunk('members/getMember', async (userId: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await axios.post(`${process.env.REACT_APP_API}/user/detailsByAdmin`, {
    token,
    userId,
  }).then((result: any) => {
    return result.data;
  });

  console.log('res', data);
  if (data.code === 100) {
    return data.user;
  } else {
    return null;
  }
});

export const addMember = createAsyncThunk('members/addMember', async (form: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await axios.post(`${process.env.REACT_APP_API}/user/createAccountByAdmin`, {
    token,
    ...form
  }).then((result: any) => {
    return result.data;
  });

  return data;
});


export const updateMember = createAsyncThunk('members/updateMember', async (params: any, { dispatch }) => {
  const { key, status } = params;

  db.ref(`/users/${key}/status`).set(status);

  return true;
});

export const createMember = createAsyncThunk('members/createMember', async (params: any, { dispatch }) => {

  return true;
});


const initialState:any = null;

const MembersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: { membersReset: () => initialState, },
  extraReducers: (builder) => {
    builder.addCase(getMembers.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  }
});

export const { membersReset } = MembersSlice.actions;

export default MembersSlice.reducer;
