import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth } from '../../firebase/Firebase';
import axios from 'axios';

export const getUser = createAsyncThunk('user/getUser', async (params: any, { dispatch }) => {
  const token = await auth.currentUser?.getIdToken();

  const data = await axios.post(`${process.env.REACT_APP_API}/user/details`, {
    token,
  }).then((result: any) => {
    return result.data;
  });

  if (data.code === 100 && data.details.isAdmin === 1) {
    return data.details;
  } else {
    return null;
  }
});

export const logout = createAsyncThunk('user/logout', async (params: any, { dispatch }) => {
  auth.signOut();

  dispatch(userReset());
});

const initialState:any = null;

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: { userReset: () => initialState, },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state: any, action: any) => {
      return action.payload;
    });
  }
});

export const { userReset } = UserSlice.actions;

export default UserSlice.reducer;
