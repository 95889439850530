import { Outlet } from 'react-router-dom';


const Guest = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default Guest;
