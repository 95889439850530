import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { bindActionCreators } from '@reduxjs/toolkit';
// import { Splash } from 'components';
import { onAuthStateChanged } from 'firebase/auth';
import { getUser } from '../redux/reducers/UserSlice';
import { auth } from '../firebase/Firebase';

interface AuthProps {
  children?: React.ReactNode;
  state?: any;
}

const Auth: React.FC<AuthProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const [waitAuthCheck, setWaitAuthCheck] = useState(true);

  const firebaseCheck = () => {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, async(authUser: any) => {
        if (authUser) {
          dispatch(getUser({}));

          resolve(true);
        }
      });

      resolve(true);
    });
  };

  useEffect(() => {
    const initLoad = async () => {
      return Promise.all([ firebaseCheck() ])
        .then((res) => {
          setInterval(() => {
            setWaitAuthCheck(false);
          }, 1000);
        });
    };

    // console.log('waitAuthCheck', waitAuthCheck);
    if (waitAuthCheck) {
      initLoad();
    }
  }, []);

  return waitAuthCheck && auth ? (
    <></>
  ) :
    <>
      {children}
    </>;
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {},
    dispatch,
  );
};

export default connect(null, mapDispatchToProps)(Auth);