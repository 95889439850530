import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

import Guest from './authentication/Guest';
import './index.scss';
import Loader from './shade/Loaders/Loaders';

const NotFound = React.lazy(() => import('./pages/Authentication/404Error/404Error'));
const UserApp = React.lazy(() => import('./shade/layouts/UserApp'));
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const Member = React.lazy(() => import('./pages/Members/Member'));
const Members = React.lazy(() => import('./pages/Members/Members'));
const MemberDetails = React.lazy(() => import('./pages/Members/MemberDetails'));
const Orders = React.lazy(() => import('./pages/Orders/Orders'));
const Order = React.lazy(() => import('./pages/Orders/Order'));
const Withdrawals = React.lazy(() => import('./pages/Withdrawals/Withdrawals'));
const Withdrawal = React.lazy(() => import('./pages/Withdrawals/Withdrawal'));
const Items = React.lazy(() => import('./pages/Items'));
const Packages = React.lazy(() => import('./pages/Packages'));
const AuthLogin = React.lazy(() => import('./authentication/Login'));
const AuthSignup = React.lazy(() => import('./authentication/Signup'));

const App: React.FC = () => {
  const { user } = useAppSelector((state: any) => state);

  console.log('user', user);

  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          {!user ? (
            <Route path="/" element={<Guest />}>
              <Route index element={<AuthLogin />} />

              <Route
                path="/authentication/login"
                element={<AuthLogin />}
              />
              <Route
                path="/authentication/signup"
                element={<AuthSignup />}
              />

              <Route path="*" element={<NotFound />} />
            </Route>
          ) : (
            <Route path="/" element={<UserApp />}>
              <Route
                path="/"
                element={<Dashboard />}
              />

              <Route
                path="/dashboard"
                element={<Dashboard />}
              />

              <Route
                path="/items"
                element={<Items />}
              />

              <Route
                path="/packages"
                element={<Packages />}
              />

              <Route path="/member/new" element={<Member />} />
              <Route path="/member/details/:id" element={<MemberDetails />} />
              <Route path="/members/stockiest" element={<Members type="stockiest" />} />
              <Route path="/members/depot" element={<Members type="depot" />} />
              <Route path="/members/distributor" element={<Members type="distributor" />} />
              <Route path="/members/member" element={<Members type="member" />} />
              <Route path="/members" element={<Members />} />

              <Route path="/orders/:status" element={<Orders />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/order/:id" element={<Order />} />

              <Route path="/withdrawals/:status" element={<Withdrawals />} />
              <Route path="/withdrawals" element={<Withdrawals />} />
              <Route path="/withdrawal/:id" element={<Withdrawal />} />

              <Route path="*" element={<NotFound />} />

              {/*
              <Route
                path={`/orders/pending`}
                element={<Orders status="pending" />}
              />
              <Route
                path={`/orders/shipping`}
                element={<Orders status="shipping" />}
              />
              <Route
                path={`/orders/completed`}
                element={<Orders status="completed" />}
              />
              <Route
                path={`/orders/cancelled`}
                element={<Orders status="cancelled" />}
              />
              */}
            </Route>
          )}
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
