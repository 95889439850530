import firebase from 'firebase/compat/app';

// Add the Firebase products that you want to use
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBdz0hlGfF8ghgkECfHgZkfdLiZzXnIWbc',
  authDomain: 'app-basic-11d92.firebaseapp.com',
  databaseURL: 'https://app-basic-11d92-default-rtdb.firebaseio.com',
  projectId: 'app-basic-11d92',
  storageBucket: 'app-basic-11d92.appspot.com',
  messagingSenderId: '923375442404',
  appId: '1:923375442404:web:72298a4553ea937c06cff1'
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.database();
const auth = firebase.auth();
const storage = firebase.storage();

export { db, auth, storage };