import React, { useEffect, useState } from 'react';
import { ref, off, onValue, push, update, child } from 'firebase/database';
import { db } from '../firebase/Firebase';
import { ToastContainer, Toast, Modal, Button } from 'react-bootstrap';

export const DielContext = React.createContext<any>({
  agents: [],
  showToast: () => null,
  showMessage: () => null,
});

const toastDefault: any = {
  showToast: false,
  message: '',
};

const messageDefault: any = {
  showMessage: false,
  message: '',
  title: 'Message',
  confirmCallback: null,
};

export const DielProvider: React.FC<any> = ({ children }) => {
  const [agents, setAgents] = useState<any>({});
  const [items, setItems] = useState<any>({});
  const [packages, setPackages] = useState<any>({});
  const [toastVal, setToastVal] = useState(toastDefault);
  const [messageVal, setMessageVal] = useState(messageDefault);

  const showToast = (message: string) => {
    setToastVal({
      ...toastDefault,
      message,
      showToast: true,
    });
  };

  const showMessage = (params: any) => {
    setMessageVal({
      ...messageDefault,
      ...params,
      showMessage: true,
    });
  };

  const hideMessage = () => {
    setMessageVal({
      ...messageDefault,
      showMessage: false,
    });
  };

  useEffect(() => {
    const agentsRef = ref(db, '/users');
    onValue(agentsRef, (snap) => {
      setAgents(snap.val() ?? {});
    });

    const itemsRef = ref(db, '/items');
    onValue(itemsRef, (snap) => {
      setItems(snap.val() ?? {});
    });

    const packagesRef = ref(db, '/packages');
    onValue(packagesRef, (snap) => {
      setPackages(snap.val() ?? {});
    });

    return (() => {
      off(ref(db, '/users'));
      off(ref(db, '/items'));
      off(ref(db, '/packages'));
    });
  }, []);

  return (
    <DielContext.Provider
      value={{
        agents,
        items,
        packages,
        showToast,
        showMessage,
      }}
    >
      { children }

      <ToastContainer className="p-3 mt-5 pt-5" position="top-center">
        <Toast delay={5000} autohide show={toastVal.showToast} onClose={() => {
          setToastVal({
            ...toastVal,
            showToast: false,
            message: '',
          });
        }}
        >
          {/*<Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">Bootstrap</strong>
            <small className="text-muted">just now</small>
          </Toast.Header>*/}
          <Toast.Body>{toastVal.message}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal show={messageVal.showMessage} onHide={hideMessage}>
        {messageVal.title && (
          <Modal.Header closeButton>
            <Modal.Title>{messageVal.title}</Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>
          <p>{messageVal.message}</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setMessageVal({
              ...messageVal,
              showMessage: false,
            });
          }}
          >Close
          </Button>

          {messageVal.confirmCallback && (
            <Button variant="primary" onClick={() => {
              messageVal.confirmCallback();
              setMessageVal({
                ...messageVal,
                showMessage: false,
              });
            }}
            >Ok
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </DielContext.Provider>
  );
};
